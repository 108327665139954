/* Create a custom checkbox */
.check-box-size {
  border: 1px solid var(--primaryColor);
  height: 15px;
  width: 15px;
}

.remaining-height {
  height: calc(100% - 64px);
}

.container-height {
  height: calc(100% - 100px);
}

.height-and-width-44px {
  height: 44px;
  width: 44px;
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.display-none {
  display: none;
}

/* On mouse-over, add a grey background color */
.user-select-none:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.user-select-none input:checked ~ .checkmark {
  background-color: var(--primaryColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  z-index: 1;
}

/* Show the checkmark when checked */
.user-select-none input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.user-select-none .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.top-box,
.bottom-box {
  height: 8px;
  width: 8px;
}

/* .top-box {
  background-color: red;
}

.bottom-box {
  background-color: blue;
} */

.svg-aligner {
  display: block;
}

.border-right-1px {
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: #4e4e4e;
}

.timer-bar-anime {
  height: 5px;
  background-color: var(--primaryColor);
  width: 0;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}
/* REFER for clipPath -> https://bennettfeely.com/clippy/ */
.camera-focus-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  -webkit-clippath: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    25% 25%,
    75% 25%,
    75% 75%,
    25% 75%,
    25% 100%,
    100% 100%,
    100% 0%
  );
  clippath: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    25% 25%,
    75% 25%,
    75% 75%,
    25% 75%,
    25% 100%,
    100% 100%,
    100% 0%
  );
}

.height-width-1-em {
  height: 2em;
  width: 2em;
}

.dimmed-overlay {
  background: rgba(0, 0, 0, 0.25);
}

.loader {
  /* font-size: 2px; */
  text-indent: -9999em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #00a000;
  background: -moz-linear-gradient(left, #00a000 10%, rgba(0, 160, 0, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #00a000 10%,
    rgba(0, 160, 0, 0) 42%
  );
  background: -o-linear-gradient(left, #00a000 10%, rgba(0, 160, 0, 0) 42%);
  background: -ms-linear-gradient(left, #00a000 10%, rgba(0, 160, 0, 0) 42%);
  background: linear-gradient(to right, #00a000 10%, rgba(0, 160, 0, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #00a000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
